import { defineStore } from "pinia";
import { ref } from "vue";

import { GlobalSettingsApi, type ISettingRow, type ISetting } from "..";

export const useGlobalSettingStore = defineStore("globalSettings", () => {
    const settings = ref<ISettingRow[]>([]);

    async function fetchAll(): Promise<void> {
        settings.value = await GlobalSettingsApi.fetchAll();
    }
    function findSetting(name: string): ISetting {
        const finded = settings.value.find(
            (item: ISettingRow) => item.name === name,
        );

        const status: boolean = !!finded ? finded.value === "true" : false;

        return {
            status,
            value: finded?.value || null,
            data: finded,
        };
    }

    return {
        settings,
        fetchAll,
        findSetting,
    };
});
