<template>
    <Seo />
    <RouterView></RouterView>
    <Toast position="bottom-center"></Toast>
    <ConfirmDialog class="w-3" :draggable="false" />
</template>

<script setup lang="ts">
import { onMounted } from "vue";

import Toast from "primevue/toast";
import ConfirmDialog from "primevue/confirmdialog";
import { Seo } from "@/shared/ui/marketing";

import { useRecaptchaProvider } from "vue-recaptcha";

import { useGlobalSettingStore } from "@/entities/GlobalSettings";

const storeGlobalSettings = useGlobalSettingStore();

useRecaptchaProvider();

onMounted(() => {
    storeGlobalSettings.fetchAll();
});
</script>
